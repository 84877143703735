import axios from 'axios'
// import app from '@/main'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

// console.log(app.$i18n.locale)

request.interceptors.request.use(config => {
  if (!config.ignoreLocale) {
    config.params = {
      ...config.params,
      // _locale: app.$i18n.locale,
      _locale: 'en'
    }
  }
  return config
})

request.interceptors.response.use(response => {
  return response.data
}, ({ response }) => {
  // Notification.error(response.data || response.statusText)
})

export default request
