import request from '@/utils/request'

const team = process.env.VUE_APP_TEAM

export function getList (extra = {}) {
  return request.get('/events', {
    params: {
      team_contains: team,
      _sort: 'time:DESC',
      ...extra
    }
  })
}

export function getDetail (id) {
  return request.get(`/events/${id}`)
}

export function addInsightFeedback (feedback) {
  return request.post('/insight-feedbacks', feedback)
}
